import "../sass/main.scss";

import { ZDK, ZDKChain, ZDKNetwork } from "@zoralabs/zdk";
import { tokensGetTokenBalances } from "@tzkt/sdk-api";

const app = document.createElement("div");
app.id = "app";
document.body.appendChild(app);

const ETH_ADDRESS = "0x10cbF38Df8B0bF30FCC3144da1dA4f1c1DEab0c5";
const XTZ_ADDRESS = "tz1VzgtroFoUddtieN2UfuQFcPvnWQKsxZVV";
const API_ENDPOINT = "https://api.zora.co/graphql";
import * as Oazapfts from "@tzkt/oazapfts/";
const zdk = new ZDK({
  endpoint: API_ENDPOINT,
  networks: [
    {
      network: ZDKNetwork.Zora,
      chain: ZDKChain.ZoraMainnet,
    },
    {
      network: ZDKNetwork.Ethereum,
      chain: ZDKChain.Mainnet
    }
  ],
});

const args = {
  where: {
    ownerAddresses: [ETH_ADDRESS],
  },
  includeFullDetails: true,
};

zdk.tokens(args).then((data) => {
  data.tokens.nodes.forEach((node) =>
    fetch(node.token.tokenUrl)
      .then((res) => res.json())
      .then((data) => {
        show(data.name, data.description, data.image, data.animation_url);
      })
  );
});

function show(_title, _description, _image, _url) {
  const container = document.createElement("div");
  container.className = "container";

  const imageContainer = document.createElement("div");
  imageContainer.className = "image-container";

  const image = document.createElement("img");
  image.src = _image;

  const name = document.createElement("h1");
  name.innerHTML = _title;

  const descriptionContainer = document.createElement("div");
  descriptionContainer.className = "description-container";

  const description = document.createElement("p");
  description.className = "description";
  description.innerHTML = _description;

  imageContainer.appendChild(image);
  descriptionContainer.appendChild(description);
  container.appendChild(imageContainer);
  container.appendChild(name);
  container.appendChild(descriptionContainer);

  app.appendChild(container);

  image.addEventListener("click", () => {
    window.open(_url);
  });
}

function ipfs(str) {
  str = str.replace("ipfs://", "");
  return `https://ipfs.io/ipfs/${str}`;
}

tokensGetTokenBalances({
  account: {
    eq: XTZ_ADDRESS,
  },
}).then((data) => {
  data.forEach((obj) => {
    console.log(obj);
    const { name, description, thumbnailUri, artifactUri } = obj.token.metadata;
    show(name, description, ipfs(thumbnailUri), ipfs(artifactUri));
  });
});

let timer = null;

// Snap body scroll to window multiples of window width
const snapScroll = () => {
  let scroll = app.scrollLeft;
  let width = app.clientWidth;

  const snap = Math.round(scroll / width) * width;

  app.scrollTo({
    left: snap,
    behavior: "smooth",
  });
};

// Snap scroll on resize
window.addEventListener("resize", snapScroll);

// Snap scroll on load
window.addEventListener("load", snapScroll);

// Snap scroll on touch
app.addEventListener("scroll", (e) => {
  clearTimeout(timer);
  timer = setTimeout(snapScroll, 50);
});

// Snap scroll on keyup
window.addEventListener("keyup", snapScroll);

// // Snap scroll on touchend
// window.addEventListener("touchend", () => {
//   clearTimeout(timer);
//   timer = setTimeout(snapScroll, 100);
// });
